import groupBy from "lodash/groupBy";
import range from "lodash/range";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { AppDispatch } from "../../rtk-core/src/app/store";
import { getElementTypes } from "../../rtk-core/src/features/elementTypes";
import {
  IElement,
  IElementControls,
  getElementControls,
  getElements,
  getElementsFromControls,
  updateElementControls,
  updateElementControlsAndMaxCost,
  updateElementTypeControl,
} from "../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getPicksProposed } from "../../rtk-core/src/features/manage";
import { getTeamsById } from "../../rtk-core/src/features/teams";
import { getChallengeAssets } from "../../utils/challenge";
import { integerToMoney } from "../../utils/money";
import Alert from "../Alert";
import BoldLinkButton from "../BoldLinkButton";
import ElementFilter from "../ElementFilter";
import ElementListRow from "../ElementListRow";
import ElementSort from "../ElementSort";
import { ElementTable } from "../ElementTable";
import {
  CheckboxButton,
  Option,
  SearchField,
  SelectField,
} from "../FieldRenderers";
import { ControlArrowLeft } from "../Icons/Arrows";
import Paginator from "../Paginator";
import SubHeading from "../SubHeading";
import { VisuallyHidden } from "../Utils";
import {
  ButtonWrap,
  ElementListElement,
  ElementListFields,
  ElementListOpp,
  ElementListPrice,
  ElementListStat,
  ElementListStatus,
  ElementTypeButton,
  ElementTypeHeading,
  ElementsShown,
  Form,
  StyledELementList,
} from "./styles";
import { IElementList } from "./types";

const ElementList: React.FC<IElementList> = ({ hideRef, hideSidebar }) => {
  const dispatch = useDispatch<AppDispatch>();

  const activeEvent = useAppSelector(getActiveEvent);
  const allElements = useAppSelector((state) =>
    getElements(state, activeEvent.id)
  );
  const controls = useAppSelector(getElementControls);
  const currencyDivisor = 10;
  const elementTypes = useAppSelector((state) =>
    getElementTypes(state, activeEvent.id)
  );
  const elements = useAppSelector(getElementsFromControls);
  const proposedPicks = useAppSelector((state) =>
    getPicksProposed(state, activeEvent.id)
  );
  const teamsById = useAppSelector(getTeamsById);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    updateControlsAndMaxCost({
      ...controls,
      filter: "all",
      sort: "total_points",
      search: "",
      excludeRemoved: true,
      getUnavailable: false,
      getSpecial: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const challenge = getChallengeAssets(activeEvent.id);

  if (!challenge) {
    return null;
  }

  const { copy } = challenge;

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    updateControlsAndMaxCost({
      ...controls,
      filter: e.target.value,
    });
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      sort: e.target.value,
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      search: e.target.value,
    });
  };

  const handleMaxCostChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      maxCost: Number(e.target.value),
    });
  };

  const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      getSpecial: e.currentTarget.checked,
    });
  };

  const handleAvailableChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      getYetToPlay: e.currentTarget.checked,
    });
  };

  const paginateAndGroup = (elements: IElement[], pageSize: number) => {
    const start = (page - 1) * pageSize;
    return {
      data: groupBy(elements.slice(start, start + pageSize), "element_type"),
      totalPages: Math.ceil(elements.length / pageSize),
    };
  };

  const showElementType = (elementTypeId: number) => {
    dispatch(updateElementTypeControl(elementTypeId));
  };

  const updateControls = (controls: IElementControls) =>
    dispatch(updateElementControls(controls));

  const updateControlsAndMaxCost = (controls: IElementControls) =>
    dispatch(updateElementControlsAndMaxCost(controls));

  const { data, totalPages } = paginateAndGroup(elements.data, 30);
  const showAvailableFilter = Boolean(allElements.find((e) => !e.can_transact));

  return (
    <StyledELementList>
      <div className="mx-3 flex items-center justify-between">
        <div className="my-3">
          <SubHeading>Player Selection</SubHeading>
        </div>
        <ButtonWrap>
          <BoldLinkButton onClick={() => hideSidebar()} ref={hideRef}>
            <div className="mr-1">
              <ControlArrowLeft color="primary" />
            </div>
            Back
          </BoldLinkButton>
        </ButtonWrap>
      </div>
      <Form onSubmit={(e) => e.preventDefault()}>
        <ElementListFields>
          <SearchField
            id="search"
            name="search"
            label="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSearchChange(e)
            }
            placeholder="Search for player&#8230;"
            value={controls.search}
          />
          <ElementFilter
            handleFilterChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleFilterChange(e)
            }
          />
          <ElementSort
            handleSortChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleSortChange(e)
            }
          />
          <SelectField
            id="maxCost"
            name="maxCost"
            label="Max cost"
            value={controls.maxCost}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleMaxCostChange(e)
            }
            hint={`Between ${integerToMoney(
              elements.minCost,
              currencyDivisor
            )} and ${integerToMoney(elements.maxCost, currencyDivisor)}`}
          >
            {range(elements.maxCost, elements.minCost - 1, -5).map((cost) => (
              <Option
                key={cost}
                value={cost}
                aria-selected={controls.maxCost === Number(cost)}
              >
                {integerToMoney(cost, 10)}
              </Option>
            ))}
          </SelectField>
          {showAvailableFilter && (
            <CheckboxButton
              id="isAvailable"
              name="isAvailable"
              label={"Show Available Players"}
              onChange={handleAvailableChange}
            />
          )}
          {copy.filterLabel && (
            <CheckboxButton
              id="isSpecial"
              name="isSpecial"
              label={copy.filterLabel}
              onChange={handleCheckboxChange}
            />
          )}
        </ElementListFields>
      </Form>
      <ElementsShown>
        <Alert>
          <strong>{elements.data.length}</strong> players shown
        </Alert>
      </ElementsShown>
      <div className="mx-3">
        {elementTypes.map(
          (et) =>
            data[et.id] && (
              <div key={et.id}>
                <ElementTypeButton onClick={() => showElementType(et.id)}>
                  <ElementTypeHeading>{et.plural_name}</ElementTypeHeading>
                </ElementTypeButton>
                <ElementTable key={et.id}>
                  <thead>
                    <tr>
                      <ElementListStatus scope="col">
                        <VisuallyHidden>Status</VisuallyHidden>
                      </ElementListStatus>
                      <ElementListElement scope="col">
                        <VisuallyHidden>Player</VisuallyHidden>
                      </ElementListElement>
                      <ElementListOpp scope="col">OPP</ElementListOpp>
                      <ElementListStat scope="col">**</ElementListStat>
                      <ElementListPrice scope="col">Price</ElementListPrice>
                    </tr>
                  </thead>
                  <tbody>
                    {data[et.id].map((e) => (
                      <ElementListRow
                        key={e.id}
                        element={e}
                        hideSidebar={hideSidebar}
                        isProposed={Boolean(
                          proposedPicks.find((pick) => pick.element === e.id) ||
                            !e.can_transact
                        )}
                        team={teamsById[e.team]}
                        currencyDivisor={currencyDivisor}
                        sort={controls.sort}
                      />
                    ))}
                  </tbody>
                </ElementTable>
              </div>
            )
        )}
      </div>
      <Paginator totalPages={totalPages} page={page} setPage={setPage} />
    </StyledELementList>
  );
};

export default ElementList;
