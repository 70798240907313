import { useSelector } from "react-redux";
import { getElementsById } from "../../rtk-core/src/features/elements";
import ElementPhoto from "../ElementPhoto";
import ElementShirt from "../ElementShirt";
import { IElementImageProps } from "./types";

const ElementImage = ({ alt, elementId, sizes }: IElementImageProps) => {
  const element = useSelector(getElementsById)[elementId];
  return element.element_type === 5 ? (
    <ElementPhoto alt={alt} elementId={element.id} sizes={sizes} />
  ) : (
    <ElementShirt alt={alt} elementId={element.id} sizes={sizes} />
  );
};

export default ElementImage;
