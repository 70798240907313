import _ from "lodash";
import React from "react";
import { Option, SelectField } from "../FieldRenderers";
import { StyledFieldWrap } from "./styles";
import DropdownProps from "./types";

const Dropdown: React.FC<DropdownProps> = ({
  formations,
  selectedFormation,
  handleFilterChange,
}) => (
  <StyledFieldWrap>
    <SelectField
      id="formation"
      name="filter"
      label="Choose formation"
      value={selectedFormation}
      onChange={handleFilterChange}
    >
      {formations.map((formation, index) => {
        const formationParts = formation.split("-");

        // Apply condition and skip the last part
        const displayFormation =
          formationParts[0] === "1" || formationParts[0] === "0"
            ? _.slice(formationParts, 1, -1).join("-")
            : _.slice(formationParts, 0, -1).join("-");

        return (
          <Option
            key={index}
            value={formation}
            aria-selected={selectedFormation === formation}
          >
            {displayFormation}
          </Option>
        );
      })}{" "}
    </SelectField>
  </StyledFieldWrap>
);

export default Dropdown;
