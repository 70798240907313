interface PhaseAssets {
  title: string;
  description: string;
}

export const getPhaseAssets = (phaseId: number) => {
  const PhaseAssets: Record<number, PhaseAssets> = {
    40: {
      title: "5-a-side",
      description:
        "Introducing four straight Gameweeks of 5-a-side action. Compete for the best rank in the first FPL Challenge Event!",
    },
    41: {
      title: "International Series",
      description:
        "Celebrate the diversity of the Premier League. Compete in three different challenges using the best players from three different regions!",
    },
    42: {
      title: "Test Lab",
      description:
        "Shape the future of Fantasy Premier League by joining in on our experiments. This series we are running four tests that focus on earning points through different players and playstyles. What new FPL assets become must-haves?",
    },
    43: {
      title: "Goal Diggers",
      description:
        "Calling all the bargain hunters and super savers. Can you get the best bang for your buck?",
    },
    44: {
      title: "Festive Fixtures",
      description:
        "Time to flex your cheerful spirit and ring in the new year with these festive challenges.",
    },
    45: {
      title: "Transfer Window Special",
      description:
        "The January window is now open for business - show off your managerial skills with our three transfer themed challenges.",
    },
    46: {
      title: "Assistant Manager Series",
      description:
        "Celebrating the introduction of the Assistant Manager chip, choose any Premier League manager to give your team a boost!",
    },
  };
  // check PhaseAssets has a key for the phase otherwise return null
  return Object.hasOwn(PhaseAssets, phaseId) ? PhaseAssets[phaseId] : null;
};
