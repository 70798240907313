import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RootState } from "../../app/store";
import { getActiveOrProposedTeamChipName } from "../chips";
import { getElementsById } from "../elements";
import {
  IMyTeamDataForEvent,
  IMyTeamRequestData,
  IMyTeamResponseData,
} from "../myTeam";
import { toUpdateMyTeamAPI } from "../myTeam/selectors";
import { apiSlice } from "./apiSlice";

export const myTeamApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchMyTeam: builder.query<IMyTeamResponseData, IMyTeamRequestData>({
      queryFn: async ({ entry, event }, queryApi, extraOptions, baseQuery) => {
        const state = queryApi.getState() as RootState;

        const url = `my-team/${entry}/${event ? `${event}/` : ""}`;
        const response = await baseQuery({
          url: url,
          method: "GET",
        });
        return response.data
          ? {
              data: {
                data: response.data,
                elementsById: getElementsById(state, event),
                eventId: event,
              } as IMyTeamResponseData,
            }
          : {
              error: response.error as FetchBaseQueryError,
            };
      },
    }),
    saveMyTeam: builder.query<IMyTeamResponseData, IMyTeamRequestData>({
      queryFn: async ({ entry, event }, queryApi, extraOptions, baseQuery) => {
        const state = queryApi.getState() as RootState;

        const url = `my-team/${entry}/${event ? `${event}/` : ""}`;
        const response = await baseQuery({
          url: url,
          method: "PUT",
          body: {
            chip: getActiveOrProposedTeamChipName(state),
            picks: toUpdateMyTeamAPI(state),
          },
        });
        return response.data
          ? {
              data: {
                data: response.data,
                elementsById: state.elements.byId,
              } as IMyTeamResponseData,
            }
          : {
              error: response.error as FetchBaseQueryError,
            };
      },
    }),
    createTeamForEvent: builder.query<IMyTeamResponseData, IMyTeamDataForEvent>(
      {
        queryFn: async (
          { entry, event, picks },
          queryApi,
          extraOptions,
          baseQuery
        ) => {
          const state = queryApi.getState() as RootState;
          const response = await baseQuery({
            url: `my-team/${entry}/${event}/`,
            method: "POST",
            body: {
              picks,
            },
          });
          return response.data
            ? {
                data: {
                  data: response.data,
                  elementsById: state.elements.byId,
                } as IMyTeamResponseData,
              }
            : {
                error: response.error as FetchBaseQueryError,
              };
        },
      }
    ),
  }),
  overrideExisting: false,
});

export const { useFetchMyTeamQuery, useSaveMyTeamQuery } = myTeamApi;
