import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  getEloData,
  getEntry,
  getOverallLeagueEntryForEntry,
  getSystemClassicLeaguesForEntry,
} from "../../rtk-core/src/features/entries";
import {
  getActiveEvent,
  getEvents,
  getEventsById,
} from "../../rtk-core/src/features/events";
import { getOverallPhase } from "../../rtk-core/src/features/phases";
import {
  getPlayerData,
  ILoggedInPlayer,
} from "../../rtk-core/src/features/player";
import { getTeamsById } from "../../rtk-core/src/features/teams";
import {
  getFilteredSystemClassicLeagues,
  getLeagueUrl,
} from "../../utils/leagues";
import { getEloRank, getRankFriendlyName } from "../../utils/ranks";
import Badge from "../Badge";
import ButtonLink from "../ButtonLink";
import { ChevronRight } from "../Chevrons";
import RankBadge from "../RankBadge";
import Flag from "../Flag";
import {
  LeagueLink,
  LeagueLinks,
  LeagueLinksItem,
  OverviewBody,
  PlayerDetailItem,
  PlayerDetailList,
  RankHeader,
  RankHeaderBadge,
  SeasonStat,
  SeasonStatHeading,
  SeasonStatMainHeading,
  SeasonStatMainValue,
  SeasonStats,
  SeasonStatsMain,
  SeasonStatsSecondary,
  SeasonStatsWrap,
  SeasonStatValue,
  StatLink,
  StyledPlayerOverview,
  TeamName,
} from "./styles";
import { IPlayerOverview } from "./types";

const PlayerOverview = ({ hasProfileLink, isSidebar }: IPlayerOverview) => {
  const activeEvent = useAppSelector(getActiveEvent);

  const player = useAppSelector(getPlayerData) as ILoggedInPlayer;
  const entry = useAppSelector((state) => getEntry(state, player.entry!))!;

  const overallPhase = useAppSelector((state) => getOverallPhase(state));
  const overallLeagueEntry = useAppSelector((state) =>
    getOverallLeagueEntryForEntry(state, entry.id)
  )!;
  const overallLeagueEntryOverallPhase = overallLeagueEntry.active_phases.find(
    (p) => p.phase === overallPhase?.id
  );

  const events = useAppSelector(getEvents);
  const eventsById = useAppSelector(getEventsById);
  const eventsPlayed = entry.entered_events.length
    ? entry.entered_events.filter((eventId) => eventsById[eventId].finished)
        .length
    : 0;
  const finishedEvents = events.filter((e) => e.finished);
  const teamsById = useAppSelector(getTeamsById);

  const eloData = useAppSelector((state) =>
    entry ? getEloData(state, entry.id, activeEvent.id) : null
  );

  const systemClassicLeagues = useAppSelector((state) =>
    getSystemClassicLeaguesForEntry(state, player.entry)
  );

  const filteredSystemClassicLeagues =
    getFilteredSystemClassicLeagues(systemClassicLeagues);

  if (!entry || !eloData || !overallLeagueEntryOverallPhase) {
    return null;
  }

  const rankFriendlyName = getRankFriendlyName(eloData);
  const eloRank = getEloRank(eloData);

  const faveTeam = entry.favourite_team
    ? teamsById[entry.favourite_team]
    : null;

  const overallLeague = filteredSystemClassicLeagues.find(
    (league) => league.short_name === "overall"
  )!;
  const regionLeague = filteredSystemClassicLeagues.find((league) =>
    league.short_name?.startsWith("region")
  )!;
  const teamLeague = faveTeam
    ? filteredSystemClassicLeagues.find((league) =>
        league.short_name?.startsWith("team")
      )!
    : null;

  return (
    <StyledPlayerOverview>
      {/* Overview header */}
      <div>
        <RankHeader>
          <TeamName>{entry.name}</TeamName>
          <RankHeaderBadge>
            <RankBadge rank={eloRank} name={rankFriendlyName} />
          </RankHeaderBadge>
        </RankHeader>
        <PlayerDetailList>
          <PlayerDetailItem>
            {player.first_name} {player.last_name}
          </PlayerDetailItem>
          <PlayerDetailItem>{rankFriendlyName}</PlayerDetailItem>
          <PlayerDetailItem>
            Manager since {entry.joined_time.split("-")[0]}
          </PlayerDetailItem>
        </PlayerDetailList>
      </div>
      {/* Overview Body */}
      <OverviewBody>
        <SeasonStatsWrap>
          <SeasonStats $isSidebar={isSidebar}>
            <SeasonStatsMain>
              <SeasonStatMainHeading>Season pts</SeasonStatMainHeading>
              <SeasonStatMainValue>
                {overallLeagueEntryOverallPhase.total > 0
                  ? overallLeagueEntryOverallPhase.total
                  : "-"}
              </SeasonStatMainValue>
            </SeasonStatsMain>
            <SeasonStatsSecondary>
              <SeasonStat>
                <SeasonStatHeading>Season rank</SeasonStatHeading>
                <SeasonStatValue>
                  <StatLink
                    to={getLeagueUrl(
                      overallLeague.id,
                      overallLeague.scoring,
                      undefined,
                      activeEvent.id,
                      overallPhase?.id
                    )}
                  >
                    <span>
                      {overallLeagueEntryOverallPhase.rank
                        ? overallLeagueEntryOverallPhase.rank.toLocaleString()
                        : "-"}
                    </span>
                    <ChevronRight />
                  </StatLink>
                </SeasonStatValue>
              </SeasonStat>
              <SeasonStat>
                <SeasonStatHeading>
                  <abbr title="Gameweeks">GW's</abbr> played
                </SeasonStatHeading>
                <SeasonStatValue>
                  {finishedEvents.length
                    ? `${eventsPlayed} of ${finishedEvents.length}`
                    : `-`}
                </SeasonStatValue>
              </SeasonStat>
            </SeasonStatsSecondary>
          </SeasonStats>
        </SeasonStatsWrap>
        {/* league links */}
        <LeagueLinks>
          <LeagueLinksItem>
            {faveTeam && teamLeague && (
              <LeagueLink
                to={getLeagueUrl(
                  teamLeague.id,
                  teamLeague.scoring,
                  undefined,
                  activeEvent.id
                )}
              >
                <Badge
                  alt=""
                  team={faveTeam}
                  isPresentation={true}
                  sizes="(min-width: 24px) 24px"
                />
                <span>{faveTeam.name}</span>
                <ChevronRight />
              </LeagueLink>
            )}
          </LeagueLinksItem>
          <LeagueLinksItem>
            <LeagueLink
              to={getLeagueUrl(
                regionLeague.id,
                regionLeague.scoring,
                undefined,
                activeEvent.id
              )}
            >
              <Flag entry={entry}></Flag>
              <span>{entry.player_region_name}</span>
              <ChevronRight />
            </LeagueLink>
          </LeagueLinksItem>
        </LeagueLinks>
        {hasProfileLink && (
          <div>
            <ButtonLink to="/profile" $isFullWidth={true} $variant="dark">
              Manager profile
              <ChevronRight />
            </ButtonLink>
          </div>
        )}
      </OverviewBody>
    </StyledPlayerOverview>
  );
};
export default PlayerOverview;
