import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { isEqual, range, sortBy, zipObject } from "lodash";
import { manageApi } from "../api/manage";
import { myTeamApi } from "../api/myTeam";
import { IElementType } from "../elementTypes";
import { IElementsById } from "../elements";
import { IRules } from "../game";
import { IMyTeamResponseData, IPick, SubStatus } from "../myTeam";
import {
  IManage,
  IManageInitialiseState,
  IManagePickProposed,
  IManagePicksProposed,
  IManageTeamResponseData,
} from "./types";

const changeCaptainOrVicePrepare = (
  currentPicks: IManagePicksProposed,
  elementId: number,
  isVice: boolean
) => {
  const newCaptain = Object.values(
    currentPicks
  ).reduce<IManagePickProposed | null>(
    (memo, p) => (p && p.element === elementId ? p : memo),
    null
  );

  if (!newCaptain) {
    return currentPicks;
  }

  const attrMake = isVice ? "is_vice_captain" : "is_captain";
  const attrOther = isVice ? "is_captain" : "is_vice_captain";

  if (newCaptain[attrMake]) {
    return currentPicks;
  }

  const oldCaptain = Object.values(
    currentPicks
  ).reduce<IManagePickProposed | null>(
    (memo, p) => (p && p[attrMake] ? p : memo),
    null
  );

  let newPicks: IManagePicksProposed = { ...currentPicks };
  Object.keys(currentPicks).forEach((key: string) => {
    const currentPick = currentPicks[Number(key)];
    if (!currentPick.element) {
      newPicks[Number(key)] = currentPick;
    } else {
      const newPick = { ...currentPick };
      if (currentPick.element === elementId) {
        newPick[attrMake] = true;
        newPick[attrOther] = false;
      } else {
        newPick[attrMake] = false;
        // Handle case of roles being swapped
        if (
          oldCaptain &&
          oldCaptain.element === currentPick.element &&
          newCaptain[attrOther]
        ) {
          newPick[attrOther] = true;
        }
      }
      newPicks[Number(key)] = newPick;
    }
  });

  return newPicks;
};

const initialState: IManage = {
  activeFormation: null,
  lastChange: {
    element: undefined,
    type: "none",
  },
  pendingFormation: null,
  pendingRemovals: [],
  picksByEventId: {},
};

const manage = createSlice({
  name: "manage",
  initialState,
  reducers: {
    // changeCaptain: {
    //   reducer: (
    //     state,
    //     action: PayloadAction<{ elementId: number; eventId: number }>
    //   ) => ({
    //     ...state,
    //     picksByEventId: {
    //       ...state.picksByEventId,
    //       [action.payload.eventId]: {
    //         ...state.picksByEventId[action.payload.eventId],
    //         proposed: changeCaptainOrVicePrepare(
    //           state.picksByEventId[action.payload.eventId].proposed,
    //           action.payload.elementId,
    //           false
    //         ),
    //       },
    //     },
    //   }),
    //   prepare: (elementId: number, eventId: number) => {
    //     return { payload: { elementId, eventId } };
    //   },
    // },
    changeCaptain: (
      state,
      action: PayloadAction<{ elementId: number; eventId: number }>
    ) => ({
      ...state,
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload.eventId]: {
          ...state.picksByEventId[action.payload.eventId],
          proposed: changeCaptainOrVicePrepare(
            state.picksByEventId[action.payload.eventId].proposed,
            action.payload.elementId,
            false
          ),
        },
      },
    }),
    changeViceCaptain: (
      state,
      action: PayloadAction<{ elementId: number; eventId: number }>
    ) => ({
      ...state,
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload.eventId]: {
          ...state.picksByEventId[action.payload.eventId],
          proposed: changeCaptainOrVicePrepare(
            state.picksByEventId[action.payload.eventId].proposed,
            action.payload.elementId,
            true
          ),
        },
      },
    }),
    fetchMyTeam: (
      state,
      action: PayloadAction<{
        elementsById: IElementsById;
        eventId: number;
        picks: IPick[];
      }>
    ) => ({
      ...state,
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload.eventId]: {
          ...state.picksByEventId[action.payload.eventId],
          proposed: sortBy(action.payload.picks, "position")
            .map((e) => ({
              ...e,
              elementType: action.payload.elementsById[e.element].element_type,
              subStatus: "" as SubStatus,
            }))
            .reduce((obj: { [key: number]: IManagePickProposed }, item) => {
              obj[item.position] = item;
              return obj;
            }, {}),
          saved: sortBy(action.payload.picks, "position").reduce(
            (obj: { [key: number]: IPick }, item) => {
              obj[item.position] = item;
              return obj;
            },
            {}
          ),
        },
      },
    }),
    initialiseState: (
      state,
      action: PayloadAction<IManageInitialiseState>
    ) => ({
      ...state,
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload.eventId]: {
          ...state.picksByEventId[action.payload.eventId],
          proposed: zipObject(
            range(1, action.payload.rules.squad_squadsize + 1),
            Object.keys(
              zipObject(range(1, action.payload.rules.squad_squadsize + 1))
            ).map((key) => {
              return {
                elementType:
                  action.payload.typesByFormation[Number(key) - 1].id,
                is_captain: false,
                is_vice_captain: false,
                multiplier: 1,
                position: Number(key),
                subStatus: "" as SubStatus,
              };
            })
          ),
          saved: {},
        },
      },
    }),
    proposePick: (
      state,
      action: PayloadAction<{ eventId: number; pick: IManagePickProposed }>
    ) => ({
      ...state,
      lastChange: {
        element: action.payload.pick.element,
        type: "addition",
      },
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload.eventId]: {
          ...state.picksByEventId[action.payload.eventId],
          proposed: {
            ...state.picksByEventId[action.payload.eventId].proposed,
            [action.payload.pick.position]: action.payload.pick,
          },
        },
      },
    }),
    purgePicks: (state, action: PayloadAction) => ({
      ...state,
      picksProposed: {},
      picksSaved: {},
    }),
    removePick: (
      state,
      action: PayloadAction<{ eventId: number; position: number }>
    ) => ({
      ...state,
      lastChange: {
        element:
          state.picksByEventId[action.payload.eventId].proposed[
            action.payload.position
          ].element,
        type: "removal",
      },
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload.eventId]: {
          ...state.picksByEventId[action.payload.eventId],
          proposed: {
            ...state.picksByEventId[action.payload.eventId].proposed,
            [action.payload.position]: {
              elementType:
                state.picksByEventId[action.payload.eventId].proposed[
                  action.payload.position
                ].elementType,
              is_captain: false,
              is_vice_captain: false,
              multiplier: 1,
              position: action.payload.position,
              subStatus: "" as SubStatus,
            },
          },
        },
      },
    }),
    resetLastChange: (state) => ({
      ...state,
      lastChange: initialState.lastChange,
    }),
    resetMyTeam: {
      reducer: (
        state,
        action: PayloadAction<{
          elementsById: IElementsById;
          elementTypes: IElementType[];
          eventId: number;
          rules: IRules;
        }>
      ) => {
        let newPicksProposed = zipObject(
          range(1, action.payload.rules.squad_squadsize + 1),
          Object.keys(
            zipObject(range(1, action.payload.rules.squad_squadsize + 1))
          ).map((key) => {
            return {
              elementType: action.payload.elementTypes[Number(key) - 1].id,
              is_captain: false,
              is_vice_captain: false,
              multiplier: 1,
              position: Number(key),
              subStatus: "" as SubStatus,
            };
          })
        );

        const savedPicks = state.picksByEventId[action.payload.eventId].saved;
        if (Object.keys(savedPicks)) {
          Object.keys(savedPicks).forEach((key: string) => {
            const pick = savedPicks[Number(key)];
            if (pick) {
              newPicksProposed[Number(key)] = {
                ...pick,
                elementType:
                  action.payload.elementsById[pick.element].element_type,
                subStatus: "" as SubStatus,
              };
            }
          });
        }
        state.picksByEventId[action.payload.eventId].proposed =
          newPicksProposed;
      },
      prepare: (
        elementsById: IElementsById,
        elementTypes: IElementType[],
        eventId: number,
        rules: IRules
      ) => {
        return { payload: { elementsById, elementTypes, eventId, rules } };
      },
    },
    setActiveFormation: (state, action: PayloadAction<string>) => ({
      ...state,
      activeFormation: action.payload,
    }),
    setPendingFormation: (state, action: PayloadAction<string | null>) => ({
      ...state,
      pendingFormation: action.payload,
    }),
    setPendingRemovals: (state, action: PayloadAction<IElementType[]>) => ({
      ...state,
      pendingRemovals: action.payload,
    }),
    substitutionStart: {
      reducer: (
        state,
        action: PayloadAction<{
          eventId: number;
          pick: IManagePickProposed;
          possibleReplacements: IManagePickProposed[];
        }>
      ) => {
        let newPicksProposed = {
          ...state.picksByEventId[action.payload.eventId].proposed,
        };
        const replacementsAsPositions = action.payload.possibleReplacements.map(
          (pick) => pick.position
        );
        Object.keys(
          state.picksByEventId[action.payload.eventId].proposed
        ).forEach((key: string) => {
          if (
            state.picksByEventId[action.payload.eventId].proposed[Number(key)]
          ) {
            const p = {
              ...state.picksByEventId[action.payload.eventId].proposed[
                Number(key)
              ]!,
            };
            if (isEqual(p, action.payload.pick)) {
              p.subStatus = "instigator";
            } else if (replacementsAsPositions.indexOf(p.position) > -1) {
              p.subStatus = "target";
            } else {
              p.subStatus = "invalid";
            }
            newPicksProposed[Number(key)] = p;
          }
        });
        state.picksByEventId[action.payload.eventId].proposed =
          newPicksProposed;
      },
      prepare: (
        eventId: number,
        pick: IManagePickProposed,
        possibleReplacements: IManagePickProposed[]
      ) => {
        return { payload: { eventId, pick, possibleReplacements } };
      },
    },
    substitutionProcess: {
      reducer: (
        state,
        action: PayloadAction<{
          eventId: number;
          pick: IManagePickProposed;
          subsStart: number;
        }>
      ) => {
        const picksProposed: { [key: number]: IManagePickProposed } =
          state.picksByEventId[action.payload.eventId].proposed;
        const instigator = Object.values(picksProposed).find(
          (pick) => pick?.subStatus === "instigator"
        );
        if (!instigator) {
          state.picksByEventId[action.payload.eventId].proposed = picksProposed;
        } else {
          const target = action.payload.pick;
          // Swap positions / captain data and reset subStatus
          const newPicks = {
            ...picksProposed,
            [instigator.position]: {
              ...target,
              position: instigator.position,
              is_captain: instigator.is_captain,
              is_vice_captain: instigator.is_vice_captain,
            },
            [target.position]: {
              ...instigator,
              position: target.position,
              is_captain: target.is_captain,
              is_vice_captain: target.is_vice_captain,
            },
          };
          Object.keys(newPicks).forEach((key) => {
            const p = newPicks[Number(key)];
            if (p) {
              newPicks[Number(key)] = {
                ...p,
                subStatus: "",
              };
            }
          });
          state.picksByEventId[action.payload.eventId].proposed = newPicks;
        }
      },
      prepare: (
        eventId: number,
        pick: IManagePickProposed,
        subsStart: number
      ) => {
        return { payload: { eventId, pick, subsStart } };
      },
    },
    substitutionStop: (state, action: PayloadAction<number>) => ({
      ...state,
      picksByEventId: {
        ...state.picksByEventId,
        [action.payload]: {
          ...state.picksByEventId[action.payload],
          proposed: Object.values(state.picksByEventId[action.payload].proposed)
            .map((pick: IManagePickProposed) => {
              return {
                ...pick,
                subStatus: "" as SubStatus,
              };
            })
            .reduce((obj: { [key: number]: IManagePickProposed }, item) => {
              obj[item.position] = item;
              return obj;
            }, {}),
        },
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      manageApi.endpoints.postManageTeam.matchFulfilled,
      (state, action: PayloadAction<IManageTeamResponseData>) =>
        manage.caseReducers.fetchMyTeam(state, {
          ...action,
          payload: {
            elementsById: action.payload.elementsById,
            eventId: action.payload.eventId,
            picks: action.payload.picks,
          },
        })
    );
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) =>
        manage.caseReducers.fetchMyTeam(state, {
          ...action,
          payload: {
            elementsById: action.payload.elementsById,
            eventId: action.payload.eventId,
            picks: action.payload.data.picks,
          },
        })
    );
  },
});

export const {
  changeCaptain,
  changeViceCaptain,
  fetchMyTeam,
  initialiseState,
  proposePick,
  purgePicks,
  removePick,
  resetLastChange,
  resetMyTeam,
  setActiveFormation,
  setPendingFormation,
  setPendingRemovals,
  substitutionStart,
  substitutionProcess,
  substitutionStop,
} = manage.actions;

export default manage.reducer;
