import * as React from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import ManageTeamContext from "../../contexts/ManageTeamContext";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { AppDispatch } from "../../rtk-core/src/app/store";
import { getElementTypesById } from "../../rtk-core/src/features/elementTypes";
import { IElement } from "../../rtk-core/src/features/elements/types";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import {
  getErrors,
  getTypesNeeded,
  proposeElement,
} from "../../rtk-core/src/features/manage";
import { ITeam } from "../../rtk-core/src/features/teams/types";
import { integerToMoneyWithCurrency, isMoneyStat } from "../../utils/money";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import ElementOpponents from "../ElementOpponents";
import {
  CostCell,
  ElementSelectWrapper,
  OppCell,
  StatCell,
  StyledElementListRow,
} from "./styles";

interface IProps {
  currencyDivisor: number;
  element: IElement;
  hideSidebar: () => void;
  isProposed: boolean;
  sort: React.ReactText;
  team: ITeam;
}

const ElementListRow: React.FC<IProps> = ({
  currencyDivisor,
  element,
  hideSidebar,
  isProposed,
  sort,
  team,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const activeEvent = useAppSelector(getActiveEvent);
  const elementTypesById = useAppSelector((state) =>
    getElementTypesById(state, activeEvent.id)
  );
  const typesNeeded = useAppSelector((state) =>
    getTypesNeeded(state, activeEvent.id)
  );
  const errors = useAppSelector((state) => getErrors(state, activeEvent.id));
  const isNeedElements = Boolean(errors.needElements);

  const { setErrorMessage } = useContext(ManageTeamContext);

  const handleElementClick = async (element: IElement) => {
    let msg = "";
    const isNeededType = Boolean(typesNeeded[element.element_type]);

    if (!isNeedElements) {
      msg =
        "You cannot select this player because you already have a full squad.";
    } else if (!isNeededType) {
      msg = `You cannot select any more ${
        elementTypesById[element.element_type].plural_name
      }.`;
    } else if (!element.can_select) {
      msg =
        "You cannot select this player because he does not have a game this Gameweek.";
    } else if (element.can_transact) {
      await dispatch(proposeElement(element.id));
    }

    if (msg) {
      setErrorMessage(msg);
    }
    hideSidebar && hideSidebar();
  };

  return (
    <StyledElementListRow $isProposed={isProposed}>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <td>
        <ElementSelectWrapper onClick={() => handleElementClick(element)}>
          <ElementInTable element={element} team={team} />
        </ElementSelectWrapper>
      </td>
      <OppCell>
        <ElementOpponents elementId={element.id} />
      </OppCell>
      <StatCell>
        {isMoneyStat(sort)
          ? integerToMoneyWithCurrency(element[sort] as number, currencyDivisor)
          : element[sort]}
      </StatCell>
      <CostCell>
        {integerToMoneyWithCurrency(element.now_cost, currencyDivisor)}
      </CostCell>
    </StyledElementListRow>
  );
};

export default ElementListRow;
