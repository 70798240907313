import React from "react";
import { useSelector } from "react-redux";
import { getElementsById } from "../../rtk-core/src/features/elements";
import { getRules } from "../../rtk-core/src/features/game";
import { getTeamsById } from "../../rtk-core/src/features/teams";
import Shirt from "../Shirt";

interface IProps {
  alt: string;
  elementId: number;
  hasShadow?: boolean;
  sizes: string;
}

export const getElementShirtPath = (
  elementType: number,
  teamCode: number,
  shirtMode: string
) => {
  const suffix =
    elementType === 1 || elementType === 5 ? `_${elementType}` : "";
  return `/dist/img/shirts/${shirtMode}/shirt_${teamCode}${suffix}`;
};

const ElementShirt: React.FC<IProps> = ({ elementId, hasShadow, sizes }) => {
  const element = useSelector(getElementsById)[elementId];
  const rules = useSelector(getRules);
  const team = useSelector(getTeamsById)[element.team];

  let shirtMode = "standard";
  if (rules && rules.ui_use_special_shirts) {
    if (rules.ui_special_shirt_exclusions.indexOf(element.code) === -1) {
      shirtMode = "special";
    }
  }

  return (
    <Shirt
      hasShadow={hasShadow}
      path={getElementShirtPath(element.element_type, team.code, shirtMode)}
      sizes={sizes}
      team={team}
    />
  );
};

export default ElementShirt;
