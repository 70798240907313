import { size } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import BaseCaptain from "../../../img/icons/captain.svg?react";
import BaseDreamTeam from "../../../img/icons/dreamteam.svg?react";
import BaseGameChanger from "../../../img/icons/gamechanger.svg?react";
import BaseStatus from "../../../img/icons/status.svg?react";
import BaseViceCaptain from "../../../img/icons/vice-captain.svg?react";
import { PitchCard } from "../styles";
import { IElementName, IElementStyles } from "./types";

export const StyledPitchElement = styled(PitchCard)<IElementStyles>`
  position: relative;
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
  background-image: linear-gradient(to bottom right, #0d9356, #10c773);

  ${(props) =>
    (props.$variant === "instigator" || props.$variant === "target") &&
    css`
      opacity: 1;
      transition: all 0.15s;
    `}

  ${(props) =>
    props.$variant === "invalid" &&
    css`
      box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0 / 0.4);
      opacity: 0.4;
      transition: all 0.15s;
    `}
`;

export const ElementCost = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1rem;
  color: #fff;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: ${(props) => props.theme.fontSizes[4]};
    line-height: 1.3;
  }
`;

export const ElementPointsWrap = styled.div`
  padding: 0.1rem 0.3rem 0;
  font-family: ${(props) => props.theme.fonts.heavy};
  color: #fff;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
`;

export const PitchElementData = styled.div`
  position: absolute;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 0 0 0.3rem 0.3rem;
`;

export const ElementName = styled.div<IElementName>`
  padding: ${(props) => props.theme.space[1]};
  overflow: hidden;
  font-family: ${(props) => props.theme.fonts.heavy};
  line-height: 1;
  color: ${({ $copnr, theme }) =>
    $copnr !== null ? theme.colors.elementStatus[$copnr].color : "inherit"};
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${({ $copnr, theme }) =>
    $copnr !== null ? theme.colors.elementStatus[$copnr].bg : "#fff"};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: ${(props) => props.theme.fontSizes[0]};
  }
`;

const CaptainStyles = css`
  ${size(9)};
  color: #fff;
  fill: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(16)};
  }
`;

export const Captain = styled(BaseCaptain)`
  ${CaptainStyles}
`;

export const ViceCaptain = styled(BaseViceCaptain)`
  ${CaptainStyles}
`;

export const StyledGameChanger = styled(BaseGameChanger)`
  ${size(9)};
  color: #fff;
  fill: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(16)};
  }
`;

export const DreamTeamLink = styled(Link)`
  font-size: 0;
`;

export const DreamTeamIcon = styled(BaseDreamTeam)`
  ${size(10)}
  fill: ${(props) => props.theme.colors.primary};

  > g > polygon {
    fill: ${(props) => props.theme.colors.fantasy};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(16)};
  }
`;

export const PitchElementIconsWrap = styled.div`
  position: absolute;
  top: 3px;
  left: 2px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    top: 5px;
    left: 5px;
    gap: 4px;
  }
`;

export const StatusIcon = styled(BaseStatus)<{ $copnr: number }>`
  ${size(9)}
  position: absolute;
  top: 3px;
  right: 2px;
  color: ${({ $copnr, theme }) => theme.colors.elementStatus[$copnr].color};
  fill: ${({ $copnr, theme }) => {
    return theme.colors.elementStatus[$copnr].bg;
  }};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(16)}
    top: 5px;
    right: 5px;
  }
`;

export const ShirtWrap = styled.div`
  padding-top: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: ${(props) => props.theme.space[3]};
  }
`;

export const BadgeWrap = styled.div`
  position: absolute;
  top: 12px;
  right: 6px;
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    top: 30px;
    right: 10px;
  }
`;
