import { createAsyncThunk } from "@reduxjs/toolkit";

import { IThunkApi } from "../../app/types";
import { bootstrapApi } from "../api/bootstrap";
import { entriesApi } from "../api/entries";
import { setActiveEntry } from "../entries";
import {
  IEvent,
  getEventsById,
  getLastEvent,
  getOpenEvent,
  setActiveEvent,
} from "../events";
import { doEventChange } from "../events/thunks";
import { doFetchMyTeam } from "../myTeam";
import { getPlayerData } from "../player";
import { timeNow } from "./bootstrapSlice";
import { getBootstrapTime, getBootstrapped } from "./selectors";

export const doEntryPerEventBootstrap = createAsyncThunk<
  void,
  { entryId: string | undefined; eventId: string | undefined },
  IThunkApi
>(
  "bootstrap/doEntryPerEventBootstrap",
  async ({ entryId, eventId }, { dispatch, getState }) => {
    const bootstrapped = getBootstrapped(getState());
    if (!bootstrapped) {
      await dispatch(doBootstrap());
    }

    const eventsById = getEventsById(getState());
    const event = eventId ? eventsById[eventId] : null;
    const openEvent = getOpenEvent(getState());
    const player = getPlayerData(getState());
    const lastEvent = getLastEvent(getState())!;

    // ActiveEvent hadling
    const currentActiveEvent = getState().events.activeEvent;
    let activeEvent: IEvent;
    if (event) {
      activeEvent = event;
    } else if (player && player.default_event) {
      activeEvent = eventsById[player.default_event];
    } else if (openEvent) {
      activeEvent = openEvent;
    } else {
      activeEvent = lastEvent;
    }

    const changedActiveEvent = currentActiveEvent !== activeEvent;
    // It's important we purge the picks when we know we're changing event, this ensures
    // no selector values are based on old pick information from a previous event where
    // the rules are likely different
    changedActiveEvent && dispatch(setActiveEvent(activeEvent)); // && dispatch(purgePicks());

    // ActiveEntry handling
    const currentActiveEntry = getState().entries.activeEntry;
    let activeEntry = null;
    if (entryId) {
      activeEntry = Number(entryId);
    } else if (currentActiveEntry) {
      activeEntry = currentActiveEntry;
    } else if (player && player.entry) {
      activeEntry = player.entry;
    }

    const changedActiveEntry = currentActiveEntry !== activeEntry;
    changedActiveEntry && dispatch(setActiveEntry(activeEntry));

    // Fetch entry data for the active entry and logged in entry
    const entriesToFetch: number[] = [];
    if (activeEntry) {
      entriesToFetch.push(activeEntry);
    }
    if (player && player.entry && player.entry !== activeEntry) {
      entriesToFetch.push(player.entry);
    }
    const entryDispatches: Promise<any>[] = [];
    entriesToFetch.forEach((entryId) => {
      entryDispatches.push(
        dispatch(
          entriesApi.endpoints.fetchEntrySummary.initiate(
            {
              entryId,
              eventId: activeEvent.id,
            },
            {
              forceRefetch: true,
            }
          )
        )
      );
    });
    if (entryDispatches.length) {
      await Promise.all(entryDispatches);
    }

    // Call doEventChange to get required event specific data
    await dispatch(doEventChange(activeEvent));
    // Call doFetchMyTeam to hadle pick initialisation
    activeEvent.can_manage && (await dispatch(doFetchMyTeam(activeEvent.id)));
  }
);

export const doBootstrap = createAsyncThunk<void, void, IThunkApi>(
  "bootstrap/bootstrap",
  async (_: void, { dispatch }) => {
    const staticResponse = dispatch(
      bootstrapApi.endpoints.getBootstrapStatic.initiate(undefined, {
        forceRefetch: true,
      })
    );
    const dynamicResponse = dispatch(
      bootstrapApi.endpoints.getBootstrapDynamic.initiate(undefined, {
        forceRefetch: true,
      })
    );

    await Promise.all([dynamicResponse, staticResponse]);
  }
);

export const doCheckGame = createAsyncThunk<void, void, IThunkApi>(
  "bootstrap/checkGame",
  async (_: void, { dispatch, getState }) => {
    const REFRESH_MINUTES = 10;
    const lastBootstrap = getBootstrapTime(getState());
    if (lastBootstrap && timeNow() - lastBootstrap > REFRESH_MINUTES * 60) {
      dispatch(doBootstrap());
    }
  }
);
