import _ from "lodash";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import Bench from "../Bench";
import Pitch, {
  PitchRow,
  PitchTeam,
  PitchUnit,
  ManagerRow,
  TechnicalArea,
} from "../Pitch";
import SquadPitchUnit from "../SquadPitchUnit";
import { IProps } from "./types";

const PitchFormation: React.FC<IProps> = ({
  formation,
  picks,
  showSidebar,
}) => {
  const activeEvent = useAppSelector(getActiveEvent);
  const rules = useAppSelector((state) => getRules(state, activeEvent.id));
  if (!rules) {
    return null;
  }

  // Calculates the positions per elementType for the starting 11
  const positionsByElementType = (function () {
    let positions: {
      [key: number]: number[];
    } = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
    };

    let positionCount = 1;
    formation.split("-").forEach((value, index) => {
      _.range(1, Number(value) + 1).forEach((i: number) => {
        positions[index + 1].push(positionCount);
        positionCount++;
      });
    });
    return positions;
  })();

  // Calculates the positions on the pitch based on the current formation
  const positionsInFormation = (function () {
    const positionsByEt: {
      [key: number]: number[];
    } = {
      1: [1, 2],
      2: [3, 4, 5, 6, 7],
      3: [8, 9, 10, 11, 12],
      4: [13, 14, 15],
      5: [16],
    };

    const positions: number[] = [];
    formation.split("-").forEach((value: string, index: number) => {
      positions.push(...positionsByEt[index + 1].slice(0, Number(value)));
    });

    positions.push(
      ...Array.from({ length: rules.squad_squadsize }, (_, i) => i + 1).filter(
        (pos: number) => !positions.includes(pos)
      )
    );

    return positions;
  })();

  const bench = positionsInFormation
    .filter((p: number) => p > rules.squad_squadplay)
    .sort();

  const sharedPitchElementProps = (pos: number) => ({
    pick: picks.find((p) => p.position === pos)!,
    showSidebar: showSidebar ? showSidebar : () => {},
  });

  return (
    <Pitch sponsor="default">
      <PitchTeam>
        {Object.entries(positionsByElementType)
          .filter(([key]) => Number(key) < 5)
          .map(([, positions]: [string, number[]], index: number) => {
            return (
              <PitchRow key={index}>
                {positions.map((position: number) => (
                  <PitchUnit key={`${index}${position}`}>
                    <SquadPitchUnit
                      key={position}
                      {...sharedPitchElementProps(position)}
                    />
                  </PitchUnit>
                ))}
              </PitchRow>
            );
          })}
        {Object.entries(positionsByElementType)
          .filter(([key]) => Number(key) === 5) // filter to show managers only (element type 5)
          .map(([, positions]: [string, number[]], index: number) => {
            return (
              <ManagerRow key={index}>
                {positions.map((position: number) => (
                  <TechnicalArea key={`manager_${position}`}>
                    <PitchUnit key={`${index}${position}`}>
                      <SquadPitchUnit
                        key={position}
                        {...sharedPitchElementProps(position)}
                      />
                    </PitchUnit>
                  </TechnicalArea>
                ))}
              </ManagerRow>
            );
          })}
      </PitchTeam>
      {picks.length > rules.squad_squadplay && (
        <Bench>
          <PitchRow>
            {
              // Render Bench Elements
              bench.map((position: number) => (
                <PitchUnit key={`bench_${position}`}>
                  <SquadPitchUnit
                    key={position}
                    {...sharedPitchElementProps(position)}
                  />
                </PitchUnit>
              ))
            }
          </PitchRow>
        </Bench>
      )}
    </Pitch>
  );
};

export default PitchFormation;
