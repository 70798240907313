import { tail } from "lodash";
import { useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import DialogBody from "../components/DialogBody";
import DialogHeader from "../components/DialogHeader";
import PitchElement from "../components/PitchCard/PitchElement";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import {
  IElementType,
  getElementTypes,
} from "../rtk-core/src/features/elementTypes";
import { getActiveEvent } from "../rtk-core/src/features/events";
import {
  getPendingFormation,
  getPendingRemovals,
  getPicksProposed,
  removeElement,
} from "../rtk-core/src/features/manage";

const PlayerRemovalDialogBox = styled.div`
  box-sizing: border-box;
  min-width: 0;
  margin-bottom: 0.8rem;
`;

const PlayerRemovalDialogCopy = styled.div`
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-align: center;
`;

const PlayerRemovalChoices = styled.div`
  text-align: center;
`;

const PlayerRemovalButtonWrap = styled.div`
  text-align: center;
`;

const PlayerRemovalHeading = styled.h4`
  display: inline;
`;

interface IPlayerRemovalDialogProps {
  elementType: IElementType;
  handleHide: () => void;
}

const PlayerRemovalDialog: React.FC<IPlayerRemovalDialogProps> = ({
  elementType,
  handleHide,
}) => {
  const dispatch = useAppDispatch();

  const activeEvent = useAppSelector(getActiveEvent);
  const elementTypes = useAppSelector(getElementTypes);

  const pendingFormation = useAppSelector(getPendingFormation);
  const pendingRemovals = useAppSelector(getPendingRemovals);
  const proposedPicks = useAppSelector((state) =>
    getPicksProposed(state, activeEvent.id)
  );

  const [initialRemovals] = useState(useAppSelector(getPendingRemovals));

  if (!pendingFormation) {
    return null;
  }

  const removalTextForEts: string[] = [];
  elementTypes.forEach((et) => {
    const removalsForEt = initialRemovals.filter((pr) => pr === et);
    if (removalsForEt.length) {
      removalTextForEts.push(
        removalsForEt.length > 1
          ? `${removalsForEt.length} ${et.plural_name.toLowerCase()}`
          : `1 ${et.singular_name.toLowerCase()}`
      );
    }
  });

  return (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>Changing formation</DialogHeader>
      <DialogBody isPadded={true}>
        <PlayerRemovalDialogCopy>
          You need to remove{" "}
          <PlayerRemovalHeading>
            {removalTextForEts.map((s) => s).join(" and ")}
          </PlayerRemovalHeading>{" "}
          to switch to {tail(pendingFormation.split("-")).join("-")}
        </PlayerRemovalDialogCopy>
        <PlayerRemovalDialogCopy>
          <PlayerRemovalHeading>
            Select {pendingRemovals.filter((pr) => pr === elementType).length}{" "}
            {pendingRemovals.filter((pr) => pr === elementType).length > 1
              ? elementType.plural_name.toLowerCase()
              : elementType.singular_name.toLowerCase()}{" "}
            to remove
          </PlayerRemovalHeading>
        </PlayerRemovalDialogCopy>
        <PlayerRemovalChoices>
          <PlayerRemovalDialogBox>
            {proposedPicks
              .filter((p) => p.element && p.elementType === elementType.id)
              .map((p) => {
                return (
                  <PitchElement
                    pick={p}
                    onClick={() => dispatch(removeElement(p.position))}
                  />
                );
              })}
          </PlayerRemovalDialogBox>
        </PlayerRemovalChoices>
        <PlayerRemovalButtonWrap>
          <PlayerRemovalDialogBox>
            <Button onClick={handleHide} $variant="tertiary">
              Cancel
            </Button>
          </PlayerRemovalDialogBox>
        </PlayerRemovalButtonWrap>
      </DialogBody>
    </Dialog>
  );
};

export default PlayerRemovalDialog;
