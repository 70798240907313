import { isEmpty } from "lodash";
import { useContext } from "react";
import ManageTeamContext, {
  ISaveState,
} from "../../contexts/ManageTeamContext";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { getEntry } from "../../rtk-core/src/features/entries";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  IManageTeamCreateData,
  autoComplete,
  doSaveMyTeam,
  getErrors,
  getPicksProposed,
  getPicksSaved,
  hasTeamChanged,
  reset,
} from "../../rtk-core/src/features/manage";
import { IPlayer, getPlayerData } from "../../rtk-core/src/features/player";
import { getEntryEnteredEvent } from "../../utils/entries";
import { pushToGtagDataLayer } from "../../utils/gtag";
import Button from "../Button";
import FormationDropDown from "../FormationDropDown";
import { ActionBar, ButtonBar, FormationSelectWrap } from "./styles";

interface IProps {
  createEntryData?: IManageTeamCreateData;
  formation: string;
  formations: string[];
  onChange: (newFormation: string) => void;
}

const SquadActionBar: React.FC<IProps> = ({
  createEntryData,
  formation,
  formations,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const activeEvent = useAppSelector(getActiveEvent);
  const hasChanged = useAppSelector((state) =>
    hasTeamChanged(state, activeEvent.id)
  );
  const player = useAppSelector(getPlayerData) as IPlayer;
  const playerEntry = useAppSelector((state) =>
    player && player.entry ? getEntry(state, player.entry) : null
  );
  const proposedPicks = useAppSelector((state) =>
    getPicksProposed(state, activeEvent.id)
  );
  const rules = useAppSelector((state) => getRules(state, activeEvent.id));
  const savedPicks = useAppSelector((state) =>
    getPicksSaved(state, activeEvent.id)
  );

  const { setSaveState } = useContext(ManageTeamContext);

  const handleAutoPick = async () => {
    await dispatch(autoComplete());
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  const handleReset = async () => {
    await dispatch(reset());
  };

  let saveState: ISaveState = !playerEntry
    ? "initial"
    : getEntryEnteredEvent(playerEntry, activeEvent)
    ? "update"
    : "create";

  const handleSave = async () => {
    dispatch(doSaveMyTeam({ eventId: activeEvent.id, createEntryData }))
      .unwrap()
      // Successful Save
      .then(() => {
        setSaveState(saveState);
        pushToGtagDataLayer({
          event: `ManageTeamSubmission - ${saveState}`,
        });
      })
      // Unsuccessful Save
      .catch(() => setSaveState("fail"));
  };

  const canReset = () => {
    if (savedPicks.length) {
      return hasChanged;
    }
    return true;
  };

  const errors = useAppSelector((state) => getErrors(state, activeEvent.id));

  const isValidTeam = () => {
    const captain = proposedPicks.find((pick) => pick.is_captain);
    const viceCaptain = rules?.sys_vice_captain_enabled
      ? proposedPicks.find((pick) => pick.is_vice_captain)
      : true;
    if (
      isEmpty(errors) &&
      captain &&
      viceCaptain &&
      rules &&
      proposedPicks.filter((pick) => pick.element).length ===
        rules?.squad_squadsize
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canSave = () => {
    if (savedPicks.length && isValidTeam()) {
      return hasChanged;
    } else {
      return isValidTeam();
    }
  };

  return (
    <>
      <ActionBar>
        <ButtonBar>
          <Button
            $variant="tertiary"
            $isFullWidth={true}
            onClick={handleReset}
            disabled={!canReset()}
          >
            Reset
          </Button>

          <Button
            $variant="tertiary"
            $isFullWidth={true}
            onClick={handleAutoPick}
            disabled={
              proposedPicks.filter((pick) => pick.element).length ===
              rules?.squad_squadsize
            }
          >
            Auto pick
          </Button>

          <Button
            $variant="secondary"
            $isFullWidth={true}
            onClick={handleSave}
            disabled={!canSave()}
          >
            Save team
          </Button>
        </ButtonBar>
        <FormationSelectWrap>
          <FormationDropDown
            formations={formations}
            selectedFormation={formation}
            handleFilterChange={handleChange}
          />
        </FormationSelectWrap>
      </ActionBar>
    </>
  );
};

export default SquadActionBar;
